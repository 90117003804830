<template>
    <div>
        <div>
            <button @click="openBluetooth()">开启蓝牙</button>
            <button @click="closeBluetooth()">关闭蓝牙</button>
            <button @click="connectBT()">连接设备</button>
            <button @click="disconnectBT()">断开连接</button>
            <button @click="updateBT()">修改设备信息</button>
        </div>
        <div>
            <button @click="openQRCode()">扫码</button>
        </div>
        <div>
            <button @click="openSocket()">连接socket</button>
            <button @click="closeSocket()">关闭socket</button>
            <button @click="socketSend()">发送消息</button>
        </div>
        <div>
            <button @click="getNetworkInfo()">获取网络信息</button>
        </div>
        <div>
            <button @click="m1_A04()">获取床带配置</button>
            <button @click="m1_A01A02A03A04()">更新床带配置</button>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            //蓝牙设备回调
            this.flutter.onBluetoothDevices((list) => {
                list.forEach(item => {
                    console.log(item["address"]);
                });
            })
            //蓝牙连接状态回调
            this.flutter.onConnectStatus((result) => {
                console.log(result);
            })
            //扫码回调
            this.flutter.onQRCode((result) => {
                console.log(result);
            })
            //socket消息回调
            this.flutter.onSocketMsg((result) => {
                console.log(result);
                this.m1_result_handle(result);
                // this.k1_result_handle(result);
            })
            //socket状态回调
            this.flutter.onSocketStatus((result) => {
                console.log(result);
            })
            //网络信息回调
            this.flutter.onNetworkInfo((result) => {
                console.log(result);
            })
        },
        methods: {
            openBluetooth: function () {
                this.flutter.openBluetooth();
            },
            closeBluetooth: function () {
                this.flutter.closeBluetooth();
            },
            connectBT: function () {
                // this.flutter.connectBT("D5:13:D3:16:04:8C");
                this.flutter.connectBT("D5:13:D3:10:08:5A");
            },
            disconnectBT: function () {
                this.flutter.disconnectBT();
            },
            updateBT: function () {
                let aaa = "{" +
                    "\"uuid\": \"\"," +
                    "\"major\": 1," +
                    "\"minor\": 1," +
                    "\"broadCastInterval\": 1," +
                    "\"testPower\": 1," +
                    "\"transPower\": 1" +
                    "}";
                this.flutter.updateBT(aaa);
            },
            openQRCode: function () {
                this.flutter.openQRCode();
            },
            openSocket: function () {
                // this.flutter.openSocket("192.168.4.1", 8086);
                this.flutter.openSocket("192.168.1.76", 12344);
            },
            closeSocket: function () {
                this.flutter.closeSocket();
            },
            socketSend: function () {
                this.flutter.socketSend("test_msg");
            },
            getNetworkInfo: function () {
                this.flutter.getNetworkInfo();
            },
            promiseAction: function (time, callBack) {
                return new Promise(resolve => {
                    callBack();
                    setTimeout(() => {
                        resolve({result: true})
                    }, time * 1000)
                })
            },
            m1_A01A02A03A04: function () {
                const ssid = "Tenda_50F480";
                const password = "mnmnmnmn";
                const host = "cattest.kang-cloud.com";
                const port = "8067";
                const type = "0";
                let doSomeThing = async ()=>{
                    this.flutter.socketSend("A01," + ssid + "," + password);
                    this.flutter.socketSend("A02," + host + ":" + port);
                    await this.promiseAction(1, ()=>{
                    })
                    this.flutter.socketSend("A03," + type);
                    await this.promiseAction(1, ()=>{
                    })
                    this.flutter.socketSend("A04");
                }
                doSomeThing();
            },
            m1_A04: function () {
                this.flutter.socketSend("A04");
            },
            m1_result_handle(result) {
                if (result == null) {
                    return;
                }
                const results = result.split(",");
                if (results.length === 0) {
                    return;
                }
                switch (result[0]) {
                    case "B01":
                        console.log("修改Wifi成功");
                        break;
                    case "B02":
                        console.log("修改服务器成功");
                        break;
                    case "B03":
                        console.log("修改类型成功");
                        break;
                    case "B04":
                        if(result.length < 6){
                            return;
                        }
                        //B04,Tenda_50F480,mnmnmnmn,cattest.kang-cloud.com,8067,0
                        const wifi_ssid = result[1];
                        const password = result[2];
                        const host = result[3];
                        const port = result[4];
                        const type = result[5];
                        break;
                }
            },
            k1_A01: function () {
                // A01
                // A01,STATUS$_$_
                // 例：A01,1$_$_
                //
                // 响应：
                // B01,STATUS,RET$_$_
                // 例：B01, 0,1$_$_
                this.flutter.socketSend("A01,1$_$_");
            },
            k1_A02: function () {
                // A02
                // A02,service dns,service port,indoor time,outdoor time$_$_
                // A02,192.168.1.240,8090,30,60$_$_
                //
                // 响应：
                // B02,RET$_$_
                // 例：B02,1$_$_
                const service_dns = "";
                const service_port = "";
                const local_time = "";
                this.flutter.socketSend("A02," + service_dns + "," + service_port + "," + local_time + "$_$_");
            },
            k1_A03: function () {
                // A03
                // A03,$_$_
                // 例：A03,$_$_
                //
                // 响应：
                // B03,version,mac,batt,wifi ssid,wifi pwd,service dns,service port,RFID time,BT time,heartbeat time$_$_
                // 例：A03,u1_v01,ac0b32e0b2ed,3980,kcloud105,casnetvi2016,60,10,50$_$_
                this.flutter.socketSend("A03,$_$_");
            },
            k1_A04: function () {
                // A04
                // A04,$_$_
                // 例：A04,$_$_
                //
                // 响应：
                // B04,wifi ap,2.4g test,nb net test,nb rssi,nb rsrp,nb rsrq,gnss netfix,gnss sate$_$_
                // 例：B04,7,1,1,33,-79,-8,1,25$_$_
                this.flutter.socketSend("A03,$_$_");
            },
            k1_A05_0: function () {
                this.flutter.socketSend("A05,0$_$_");
            },
            k1_A05_1: function () {
                this.flutter.socketSend("A05,0$_$_");
            },
            k1_result_handle(result) {
                if (result == null) {
                    return;
                }
                if (!result.endsWith("$_$_")) {
                    return;
                }
                result = result.replace("$_$_", "");
                const results = result.split(",");
                if (results.length === 0) {
                    return;
                }
                switch (result[0]) {
                    case "B01":
                        //B01,STATUS,RET$_$_
                        //例：B01, 0,1$_$_
                        if (result.length < 3) {
                            return;
                        }
                        const status = result[1];
                        const ret = result[2];
                        if ("0".equals(status)) {
                            // updateConnectStatus(false);
                            // disConnect();
                        } else if ("1".equals(status)) {
                            // mHandler.postDelayed(new Runnable() {
                            // @Override
                            //     public void run() {
                            //         sendA03();
                            //     }
                            // }, 3000);
                        }
                        break;
                    case "B02":
                        //B02,RET$_$_
                        //例：B02,1$_$_
                        if (result.length < 2) {
                            return;
                        }
                        break;
                    case "B03":
                        //B03,version,mac,batt,wifi ssid,wifi pwd,service dns,service port,RFID time,BT time,heartbeat time$_$_
                        //例：A03,u1_v01,ac0b32e0b2ed,3980,kcloud105,casnetvi2016,60,10,50$_$_
                        //B03,version,mac,batt,service dns,service port,indoor time,outdoor time$_$_
                        //例：B03,2,ac0b32e0b2ed,3980,192.168.1.240,8090,30,60$_$_
                        if (result.length < 6) {
                            return;
                        }
                        const version = result[1];
                        const mac = result[2];
                        const batt = result[3];
                        const service_dns = result[4];
                        const service_port = result[5];
                        const local_time = result[6];

                        break;
                    case "B04":
                        //B04,wifi test,bt test,2.4g test,rfid test,flash test,eeprom test$_$_
                        //例：B04,1,1,1,1,1,1$_$_
                        //B04,wifi ap,2.4g test,nb net test,nb rssi,nb rsrp,nb rsrq,gnss netfix,gnss sate$_$_
                        //例：B04,7,1,1,33,-79,-8,1,25$_$_
                        if (result.length < 6) {
                            return;
                        }

                        //A04/B04：命令字符
                        //wifi ap：wifi ap数量
                        //bag status：耗材带开关状态，1：打开，0：关闭
                        //nb net test：nb连网测试，1：成功，0：失败
                        //nb rssi：nb信号值
                        //nb rsrp：nb RSRP信号质量，
                        //nb rsrq：nb RSRQ信号质量，当RSRP大于-100且RSRQ大于-9，表示信号质量较好，当RSRP小于-115或RSRQ小于-15，表示网络信号质量较差。
                        const wifiApTest = result[1];
                        const bag_status = result[2];
                        const nb_net_test = result[3];
                        const nb_rssi = result[4];
                        const nb_rsrp = result[5];
                        const nb_rsrq = result[6];

                        break;
                }
            }
        }
    }
</script>
